import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import YouTube from '@material-ui/icons/YouTube';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      Sunset Strip
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: 'black',
    backgroundImage: '/band.jpg',
    color: theme.palette.success.light,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
}));

export default function Album() {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    let tempCards = [];
    (async () => {
      fetch(
        'https://lwzehufcvd.execute-api.us-east-2.amazonaws.com/default/ss-gigs-public'
      )
        .then((response) => response.json())
        .then((data) => {
          data.gigs.forEach((g) => {
            tempCards.push({
              date: g.date,
              time: `${g.startTime} - ${g.endTime}`,
              location: g.venue.address,
              title: g.venue.name,
              link: g.venue.website,
              image: '',
              ticketUrl: g.ticketUrl,
            });
          });
          setCards(tempCards);
        });
    })();
  }, []);

  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        {/* <Toolbar className={classes.root}>
          <CameraIcon className={classes.icon} />
          <Typography variant="h6" color="inherit" noWrap>
            Sunset Strip
          </Typography>
        </Toolbar> */}
      </AppBar>
      <main>
        {/* Hero unit */}
        <div
          className={classes.heroContent}
          style={{ backgroundImage: '/band.jpg' }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="palette.success.light"
              gutterBottom
            >
              Sunset Strip
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSuccessLight"
              paragraph
            >
              Authentic 80's Glam Metal
            </Typography>
            <Typography
              variant="h6"
              align="center"
              style={{ color: '#fff' }}
              paragraph
            >
              Relive the best music from the late 80's and beyond. A little
              taste of L.A. right here in New England. Featuring covers of
              Mötley Crüe, Poison, Skid Row, Bon Jovi, Whitesnake, Warrant,
              Europe, Guns n' Roses and more...
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <img
                    src="/logo.png"
                    style={{ width: '100px', height: '100px' }}
                    alt="Sunset Strip"
                  />
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <div style={{ padding: '20px' }}>
          <Container style={{ textAlign: 'center' }}>
            <Typography variant="h4" align="center" paragraph>
              Media
            </Typography>
            <div>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Dupkr30HiIU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </Container>
        </div>
        <hr />
        <Container
          className={classes.cardGrid}
          maxWidth="md"
          style={{ minHeight: '100%' }}
        >
          {/* End hero unit */}
          <Typography variant="h4" align="center" paragraph>
            Upcoming Shows
          </Typography>
          <div>
            {cards.map((card, index) => (
              <div style={{ paddingBottom: '10px' }} key={index}>
                <Card className={classes.card}>
                  {/* <CardMedia
                    className={classes.cardMedia}
                    image={card.image}
                    title="Image title"
                  /> */}
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h4">
                      {card.title}
                    </Typography>
                    <Typography>
                      <address>
                        <a
                          href={`https://bing.com/maps/default.aspx?where1=${card.location}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: 'none' }}
                        >
                          {card.location}
                        </a>
                      </address>
                    </Typography>
                    <Typography>{card.date}</Typography>
                    <Typography>{card.time}</Typography>
                    <p />
                    {card.ticketUrl && (
                      <a href={card.ticketUrl} target="_new" rel="noreferrer">
                        Click here to buy tickets
                      </a>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary">
                      <a href={card.link} target="_new" rel="noreferrer">
                        View
                      </a>
                    </Button>
                  </CardActions>
                </Card>
              </div>
            ))}
          </div>
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom></Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          <Link href="https://www.facebook.com/sunsetstriptheband">
            <Facebook />
          </Link>{' '}
          <Link href="https://www.instagram.com/sunsetstriptheband/">
            <Instagram />
          </Link>{' '}
          <Link href="https://www.youtube.com/@sunsetstripboston">
            <YouTube />
          </Link>
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
